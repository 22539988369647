<template>
  <div class="container">
    <div class="success">
      <img src="../assets/wx.png" alt="" srcset="" />
    </div>
    <div class="tips" v-if="this.$route.query.type == 1">订单支付成功</div>
    <div class="tips" v-else>领取成功</div>
    <div class="download" v-if="this.$route.query.type == 1">
      购买完成，您需要下载app学习
    </div>
    <div class="download" v-else>领取完成完成，您需要下载app学习</div>
    <div class="btn" @click="go">去下载app</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    go() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.location.href =
          "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
      } else {
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock";
      }
    },
  },
  created() {
    // this.$router.go(0);
    if (this.$route.query.type == 0) {
      document.title = "领取完成";
    } else {
      document.title = "购买完成";
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container {
  .success {
    width: 80px;
    height: 80px;
    background: #07c160;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 84px auto 24px;
    img {
      display: block;
      width: 50px;
      height: 50px;
    }
  }
  .tips {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .download {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 40px;
    text-align: center;
    width: 100%;
  }
  .btn {
    width: 140px;
    height: 44px;
    background: #ef0923;
    border-radius: 22px;
    margin: 24px auto 30px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
